import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

import NoBrainer from "./icons/noBrainer";
import SaveMoney from "./icons/saveMoney";
import SaveTime from "./icons/saveTime";
import FeatureList from "@components/shared/featureList";

const benefitsList = [
    {
        title: "Save Time",
        description:
            "We did the research and have the knowledge to connect you with top-quality copier dealers who can meet your specific needs.",
        icon: <SaveTime />,
    },
    {
        title: "Save Money",
        description:
            "You benefit from savings as dealers compete with their best price quotes to earn your business.",
        icon: <SaveMoney />,
    },
    {
        title: "Perfect Match",
        description:
            "We've assisted thousands of businesses in securing the perfect digital copier at an ideal price point.",
        icon: <NoBrainer />,
    },
];

export default function Benefits(): ReactElement {
    return (
        <FeatureList
            featureList={benefitsList}
            mainTitle="Why CopierCosts.com?"
            classes={{
                mainTitleClasses: `text-center font-bold text-2xl lg:text-3xl 2xl:text-4xl mb-14 mt-14 md:mt-24 `,
                innerSectionClasses: "lg:flex flex-wrap justify-between",
                oneBoxClasses: `text-center mb-12 lg:mb-20 w-full lg:w-[30%] py-16 ${styles.item}`,
                iconClasses: "children:mx-auto",
                titleClasses: "font-medium text-lg lg:text-2xl my-6",
                descriptionClasses:
                    "font-light text-sm lg:text-xl text-center w-4/5 mx-auto mt-2",
            }}
        />
    );
}
